@import 'styles/theme/theme';

.inline {
  display: flex;
  align-items: center;
  gap: 8px;
}

.radio {
  label {
    border-radius: 50px;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    transition: all 0.8s ease;
    -webkit-tap-highlight-color: transparent;

    .radioWrapper {
      padding: 8px;
      border-radius: 50%;
      &:hover {
        background-color: $blueLight;
      }
    }

    .radioInput {
      vertical-align: middle;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: 0;
      appearance: none;
      padding: 0;
      margin: 0;
      transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
      pointer-events: none;
      outline: none;
      border: 2px solid $secondaryGrey;

      &:focus {
        outline: none;
      }

      &:checked {
        border: 2px solid $blue;
        box-shadow: inset 0 0 0 3px $white;
        background: $blue;
      }
    }

    .radioLabel {
      display: flex;
      cursor: pointer;
      color: $secondaryGrey;
      font-weight: $font-weight-medium;
      margin-left: 4px;
      margin-top: 2px;
    }

    .activeLabel {
      color: $blue;
    }
  }
}

.disabled {
  opacity: 0.5;
  cursor: default;
  .radioWrapper {
    &:hover {
      background-color: transparent;
    }
  }
}
