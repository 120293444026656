@use 'styles/theme/theme' as theme;

.root {
  padding-left: 14px; // 2px for border left
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  &::before {
    content: '';
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 0;
    width: 2px;
    background-color: theme.$borderGrey;
    transition: background-color 0.5s;
  }

  &.withMedia {
    padding-left: 0;
    &::before {
      width: 0;
    }
    @media all and (max-width: 800px) {
      margin-left: 0px;
    }

    @media all and (max-width: 600px) {
      margin-left: -16px;
      margin-right: -16px;

      & > *:not(.media) {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }

  @media all and (max-width: 800px) {
    margin-left: -16px;
  }

  &:hover {
    &::before {
      background-color: theme.$blue;
    }
    .contentWithSeason__content,
    .productionTags {
      * {
        color: theme.$primaryGrey !important;
      }
    }
    .contentWithSeason__info {
      * {
        color: theme.$blue !important;
      }
    }
    .contentWithSeason__info_icon {
      fill: theme.$blue !important;
      display: block;
    }
    .dateList {
      .performanceIndent span {
        color: theme.$primaryGrey !important;
      }
    }
    .quickViewAction {
      color: theme.$blue !important;
    }
    .quickViewAction__icon {
      fill: theme.$blue;
    }
    .timeTag,
    .location {
      color: theme.$primaryGrey;
      span {
        color: theme.$primaryGrey;
      }
    }
    .dateTag {
      background: #edf1fd;
      color: theme.$blue5;
    }
    .clockIcon,
    .locationIcon {
      fill: theme.$primaryGrey !important;
    }
    .performance {
      &__allDates span {
        color: theme.$primaryGrey;
      }
      &__icon {
        display: block;
        fill: theme.$blue !important;
      }
    }
  }

  .performance {
    &:hover {
      .dateTag {
        background: #edf1fd;
        color: theme.$blue5;
      }

      .performance__icon,
      .contentWithSeason__info_icon,
      .performance__allDates_icon {
        display: block;
        fill: theme.$blue !important;
      }
      .contentWithSeason__info_icon {
        margin-top: -1.5px;
      }

      .timeTag,
      .location {
        width: max-content;
        color: theme.$blue;
        span {
          color: theme.$blue;
        }
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 2px;
          border-bottom: 1px solid theme.$blue;
        }
      }

      .clockIcon {
        fill: theme.$blue !important;
      }

      .locationIcon {
        fill: theme.$blue !important;
      }
    }

    @media screen and (max-width: 1024px) {
      border: 1px solid theme.$whiteSmoke;
      padding: 8px 6px;
      border-radius: 4px;
      & + & {
        margin: 6px 0;
      }

      .performance__icon,
      .contentWithSeason__info_icon {
        display: block;
        fill: theme.$blue !important;
      }
    }
  }

  @media all and (max-width: 800px) {
    grid-gap: 6px;
    &::before {
      background-color: theme.$blue;
    }
    .quickViewAction {
      color: theme.$blue !important;
    }
    .contentWithSeason__info {
      * {
        color: theme.$blue !important;
      }
    }
  }
}

.media {
  background-color: white;
  overflow: hidden;
  width: 100%;
  min-height: 194px;
  aspect-ratio: 305/194;
  margin-bottom: 4px;

  img {
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    margin: 0 -16px 8px -16px;
    width: calc(100% + 32px);
  }
}

.performanceView {
  @media all and (max-width: 800px) {
    &::before {
      background-color: theme.$blue;
    }
  }
}

.performancesTodayView {
  &::before {
    background-color: theme.$borderGrey;
  }

  @media all and (max-width: 1024px) {
    &::before {
      background-color: theme.$blue;
    }
  }
}

.tagList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 4px;
}
.productionTags {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tag {
  white-space: nowrap;
}

.availabilityTags {
  display: flex;
  grid-gap: 8px;
  .cancelledTag {
    color: theme.$red;
    background: theme.$redLight;
  }
}

.productionTag {
  background: #e5ecfb;
  color: theme.$blue;
  padding: 2px 5px;
  border-radius: 32px;
  display: inline-block;
  white-space: nowrap;
  font-size: 10px;
  line-height: 11px;
  font-weight: bold;
  height: 15px;
  &_icon {
    height: 10px;
    width: 10px;
    margin-top: -3px;
    margin-right: 4px;
    fill: theme.$blue;
  }
}

.performance {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  padding-right: 24px;
  position: relative;

  .performanceNode {
    display: flex;
  }

  & + & {
    margin-top: 8px;
  }
  &_indent {
    padding-left: 24px;

    @media all and (max-width: 800px) {
      padding-left: 0;
    }
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: none;
    @media all and (max-width: 800px) {
      display: block;
    }
  }

  &__allDates {
    margin-top: 12px;
    padding-right: 20px;
    position: relative;
    display: flex;
    & > span:first-child {
      flex: 0;
      white-space: nowrap;
    }
    & > span:nth-child(2) {
      flex: 1;
      margin-left: 4px;
      margin-right: 4px;
    }
    &_icon {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }

    @media screen and (max-width: 1024px) {
      border: 1px solid theme.$whiteSmoke;
      padding: 8px 6px;
      border-radius: 4px;

      .performance__allDates_icon {
        display: block;
        fill: theme.$blue;
        align-self: center;
        margin-left: 4px;
      }
    }
  }

  @media all and (max-width: 800px) {
    &__icon {
      fill: theme.$blue;
    }

    &__allDates {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &_icon {
        fill: theme.$blue;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

.dateTag {
  border-radius: 4px;
  display: inline-block;
  padding: 1px 3.5px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  white-space: nowrap;
  min-width: 55px;
}

.timeTag {
  display: grid;
  grid-template-columns: 35px 12px 40px;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  white-space: nowrap;
  position: relative;
}

.location {
  position: relative;
}

.locationIcon {
  margin-right: 4px;
  margin-top: -2px;
}

.quickViewAction {
  &:hover {
    text-decoration: underline;
    font-weight: medium;
  }
}

.clockIcon {
  margin-top: -2px;
  margin-right: -2px;
}

.contentWithSeason {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 4px;
  margin-top: 8px;
  &__content {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    @media all and (max-width: 800px) {
      grid-gap: 6px;
    }
  }
  &__info {
    white-space: nowrap;
    padding-right: 15px;
    position: relative;
    &_icon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: none;
      @media all and (max-width: 800px) {
        display: block;
      }
    }
  }
}

.dateList {
  margin-top: 8px;
  .performanceIndent {
    margin-top: 6px;
  }
}

.primaryCrew + .primaryCrew {
  margin-left: 8px;
}

.redMaskIcon {
  margin-left: -4px;
  margin-top: -4px;
}

.entityRoles {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 4px 16px;

  // @media all and (max-width: 800px) {
  // justify-content: space-between;
  // }
}

.workTag {
  margin-right: 4px;
}

.workTitleButton {
  outline: unset;
}

.separatorTag {
  color: theme.$secondaryGrey;
}

.isBlur{
  filter: blur(3px);
}