@import "styles/theme/theme";
@import "styles/mixins";

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $backgroundGrey;
}

.breadcrumbs {
  padding: 0 15px;
}

.appBar {
  z-index: 999;
  background-color: $white !important;
  color: $primaryGrey;
  box-shadow: none;
  margin: auto;
  border-bottom: 1px solid $tertiaryGrey;
  top: 0;
}

.appBarWithoutBreadcrumbs {
  border-bottom: solid 1px $tertiaryGrey;
}

.rootContainer {
  padding-left: 0px;
  padding-right: 0px;
  flex-grow: 1;
  min-height: 75vh;
}

.scrollArrowContainer {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 10px;
  position: fixed;
  background: $blue;
  border-radius: 44px;
  width: 44px;
  height: 44px;
  bottom: 90px;
  right: 25px;
  z-index: 3;
}

.scrollArrowIcon {
  fill: white;
  position: absolute;
}