@use 'styles/theme/theme' as theme;

.workTypeTag {
  padding: 4px;
  border-radius: 20px;
  font-size: 10px;
  line-height: 7px;
  text-transform: uppercase;
  background: theme.$whiteSmoke;
  color: theme.$secondaryGrey;
  display: inline-block;
  font-family: "Roboto", sans-serif;

  &_type {
    &_O {
      // Oratorio
      background: #fff0ff;
      color: #7f0181;
    }
    &_W {
      // Opera
      background: #fdf2f4;
      color: #8b1b2c;
    }
    &_L {
      // Operetta
      background: #fdf2f4;
      color: #8b1b2c;
    }
    &_Z {
      // Zarzuela
      background: #fff5f0;
      color: #ff6666;
    }
    &_T {
      // Musical Theater
      background: #f3fbef;
      color: #38701c;
    }
    &_P {
      // Derived
      background: #faf0ff;
      color: #660099;
    }
    &_vocal-song-cycle {
      // Vocal
      background: #f3f0ff;
      color: #3300ff;
    }
    &_ballet {
      // Ballet
      background: #fef1f2;
      color: #ee3444;
    }
    &_concert {
      // Concert
      background: #feecf7;
      color: #de0e8b;
    }
    &_sonata {
      // Sonata
      background: #f9fcf3;
      color: #6e9325;
    }
  }
}
.isBlur {
  filter: blur(3px);
}
