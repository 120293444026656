@use 'styles/theme/theme' as *;

.productionSlug {
  & + & {
    margin-top: 32px;
  }
  &.productionSlugOverview {
    & + & {
      margin-top: 28px;
    } 
  }
}
