.radioGroup {
  display: flex;
  font-size: 12px;
}

.radioWrapper {
  padding: 0 !important;
  margin: 10px 0;
}

.radio {
  margin-right: 16px;
}

.radioLabel {
  margin-top: 0 !important;
  margin-left: 8px !important;
}

.modalContent {
  padding: 16px 20px 60px 20px !important;
}

.modalContainer {
  height: 600px;
}

.drawerRoot {
  z-index: 1300;
}

.drawerPaper {
  max-height: 90%;
}

.drawerContent {
  padding: 20px 16px 56px 16px;
}
