@use 'styles/theme/theme' as *;

.switch {
  display: inline-flex;
  align-items: center;
  grid-gap: 6px;
  cursor: pointer;
  white-space: nowrap;
  @include noSelection();

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.slider {
  height: 12px;
  width: 24px;
  border-radius: 12px;
  position: relative;
  background-color: $grey10;

  &:before {
    content: '';
    position: absolute;
    height: 8px;
    width: 8px;
    left: 3px;
    bottom: 2px;
    background-color: $primaryGrey;
    transition: all 0.4s;
    border-radius: 50%;
  }

  &_active {
    background-color: $blueLight;
    &:before {
      background-color: $blue;
    }

    &_left {
      &:before {
        left: 3px;
      }
    }
    &_right {
      &:before {
        left: 13px;
      }
    }
  }
}
