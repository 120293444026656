@import 'styles/theme/theme';

.content {
  @include container($tabPadding: 0, $mobilePadding: 0);

  &_embedded {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.columnLayout {
  & + & {
    margin-top: 32px;
  }

  &_2 {
    display: grid;
    grid-template-columns: 374px minmax(auto, calc(100% - 374px));

    &.production {
      grid-template-columns: 416px minmax(auto, calc(100% - 416px));
    }

    &.reverse {
      grid-template-columns: minmax(auto, calc(100% - 374px)) 374px;
    }

    &.equalColumnWidth {
      grid-template-columns: repeat(2, minmax(auto, calc(50%)));
    }
  }

  &_3 {
    display: grid;
    grid-template-columns: 374px minmax(auto, calc(100% - 374px - 374px)) 374px;

    &.production {
      grid-template-columns: 416px minmax(auto, calc(100% - 416px - 416px)) 416px;
    }
    
    &.equalColumnWidth {
      grid-template-columns: repeat(3, minmax(auto, calc(100% / 3)));
    }
  }
}

.column {
  padding: 0 30px;

  & + & {
    border-left: 1px solid $secondaryGrey;
  }

  @media (min-width: 600px) and (max-width: 1280px) {
    padding: 0 14px;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.hideBorder {
  border: none !important;
}

.section {
  @media (max-width: 1280px) {
    padding: 0 16px;
    .section {
      padding: 0;
    }
  }

  &.inline {
    display: inline-block;
    vertical-align: middle;
    padding-right: 16px;

    @media (max-width: 900px) {
      display: block;
    }
  }

  &:is(.inline) {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &:first-child:is(.inline) ~ .inline {
    margin-top: 0;
  }

  &.marginTopNone {
    margin-top: 0 !important;
  }

  &:not(.inline) + &:not(.inline) {
    margin-top: 60px;
    @media only screen and (max-width: 600px) {
      margin-top: 40px;
    }
  }

  &:not(.inline):is(.marginTopSmall) + &:not(.inline):is(.marginTopSmall) {
    margin-top: 32px;
  }

  &:not(.inline):is(.marginTopSixteen) + &:not(.inline):is(.marginTopSixteen) {
    margin-top: 16px;
  }

  &.theme {
    &-dark {
      @media screen and (max-width: 600px) {
        background: $black;
        padding: 16px;
        margin: 0 -16px;
      }
    }
  }

  &__header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &_title {
      flex-grow: 1;
    }
  }
}
