.root {
  display: grid;
  grid-template-columns: auto minmax(240px, 260px) 240px 240px;
  grid-gap: 16px;

  @media screen and (max-width: 1023px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 0px;

    .field {
      &_orgType {
        grid-area: 2 / 1 / 3 / 3;
      }
      &_country {
        grid-area: 3 / 1 / 4 / 2;
      }
      &_city {
        grid-area: 3 / 2 / 4 / 3;
      }
      &_search {
        grid-area: 1 / 1 / 2 / 3;
      }
    }
  }
}
