@use 'styles/theme/theme' as theme;

.root {
  width: 100%;
}

.appliedFilters {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  position: relative;
  flex-wrap: wrap;
}

.chipWrapper {
  padding: 2px;
}
.chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px;
  border: 1px solid theme.$borderGrey;
  background-color: theme.$orangeLight;
  border-radius: 18px;
  cursor: pointer;
  white-space: nowrap;
  &__label {
    width: calc(100% - 18px);
    padding-right: 8px;
    line-height: 1;
    span + span {
      margin-left: 4px;
    }
  }
  &__icon {
    width: 18px;
    svg {
      height: 14px;
      width: 14px;
      fill: theme.$primaryGrey;
    }
  }

  &:hover {
    .chip__icon svg {
      fill: theme.$orange;
    }
  }
}

.clearSearchText {
  padding: 6px 8px;
  &:hover {
    color: theme.$secondaryGrey; 
  }
}

.clearIcon {
  svg {
    fill: theme.$secondaryGrey !important;
    width: 12px !important;
    height: 12px !important;
  }
}
