@import 'styles/theme/theme';

.label {
  color: $blackGrey2;
}

.listItem {
  padding: 10px 16px 10px 10px;
  border-bottom: 1px solid $whiteSmoke;
  border-left: 2px solid transparent;
  cursor: pointer;
}

.listItem:hover,
.isHighlighted {
  background-color: $blueHover;
  border-left: 2px solid $blue;
  .optionName {
    color: $blue;
  }
  .optionSubtext {
    color: $primaryGrey;
  }
}

.loginItem {
  padding: 10px 16px 10px 10px;
  text-align: center;
}

.addNewEntity {
  display: flex;
  padding: 10px;
  grid-gap: 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  background: $whiteSmoke;
}
