@use 'styles/theme/theme' as theme;

.recentlyVisited {
  display: flex;
  width: 100%;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  background: theme.$blackGrey1;
  margin-top: 60px;
  margin-bottom: -20px;
  border-bottom: 1px solid theme.$white;

  &__container {
    width: 100%;
    max-width: 1280px;
    margin: auto;
    padding: 0;

    @media screen and (max-width: 1024px){
      overflow: scroll;
    }
  }

  @media screen and (max-width: 1024px){
    padding: 16px 0px 16px 16px;
  }

  .title {
    color: theme.$white;
  }

  .list {
    width: 1280px;
    max-width: 100%;
    margin: 0;
    margin-top: 8px;
    display: flex;
    list-style: none;
    gap: 8px;
    padding: 0;
    overflow: auto;

    .itemCard {
      border: 1px solid theme.$primaryGrey;
      &__link {
        display: flex;
        padding: 0px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        align-self: stretch;
        background: theme.$blackGrey1;
        border-radius: 0;
        width: 107px;
        height: 228px;
        text-decoration: none;
        &_text {
          color: theme.$white;
          white-space: wrap;
          display: flex;
          flex-direction: column;
          text-align: center;
        }
        &_icon {
          fill: theme.$white !important;
        }
      }
    }
  }
}
