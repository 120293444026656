@use 'styles/theme/theme' as theme;

.root {
  display: flex;

  &__seasons {
    width: 100%;
    max-width: 180px;

    &_overview {
      max-width: none;
      display: flex;
      flex-direction: column;
      grid-gap: 20px;
    }

    @media screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      grid-gap: 14px;
    }
  }
  &__productions {
    width: calc(100% - 210px);
    margin-left: 30px;
    &__view_toggle {
      margin-bottom: 16px;
    }
    &__view_toggle_mobile {
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    &__seasons {
      max-width: none;
      overflow: auto;
    }
    &__productions {
      width: 100%;
      margin-left: 0;
      margin-top: 24px;
    }
  }
}

.seasonOptionBlock {
  padding: 10px;
  border-left: 1px solid theme.$whiteSmoke;
  transition: border-color 0.2s;
  display: flex;
  flex-direction: column;
  grid-gap: 3px;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px;
    background: theme.$blue;
    display: none;
  }
}

.seasonOptions {
  border: 1px solid theme.$whiteSmoke;
  border-radius: 4px;
  & + & {
    margin-top: 12px;
  }
  &__item {
    padding-left: 12px;
    border-left: 2px solid transparent;
    display: block;
    cursor: pointer;
    &:hover {
      .seasonOptionBlock {
        border-color: theme.$blue;
      }
    }
    &.active {
      .seasonOptionBlock {
        border-color: theme.$blue;
        background-color: theme.$blueLight2;
        &:after {
          display: block;
        }
      }
    }
  }
  &__type {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;
    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 12px;
      display: block;
      height: 16px;
      width: 2px;
      background: theme.$white;
      z-index: 1;
    }
    .seasonOptionBlock {
      position: relative;
      border-color: theme.$whiteSmoke !important;
      &:before {
        content: ' ';
        position: absolute;
        top: 16px;
        left: -3px;
        display: block;
        height: 5px;
        width: 5px;
        background: theme.$tertiaryGrey;
        transition: all 0.2s;
        border-radius: 50%;
      }
      &:after {
        display: none !important;
      }
    }
    &:hover {
      .seasonOptionBlock {
        &:before {
          background: theme.$blue;
        }
      }
    }
    &.active {
      border-color: theme.$blue;
      background-color: theme.$blueLight2;
      &:before {
        background: theme.$blueLight2;
      }
      .seasonOptionBlock {
        &__icon {
          fill: theme.$blue;
        }
        &:before {
          background: theme.$blue;
        }
      }
    }
    &.skipBorder {
      .seasonOptionBlock {
        border-left: 1px solid transparent;
      }
    }
    &_upcoming {
      .seasonOptionBlock {
        border-color: transparent !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    grid-gap: 14px;
    border: none;

    & + & {
      margin-top: 0;
    }

    &__item,
    &__type {
      border: 0;
      padding: 0;
      &:before {
        display: none;
      }
    }

    &__type {
      &.active {
        background-color: transparent;
      }
    }

    .seasonOptionBlock {
      border: 1px solid theme.$borderGrey !important;
      border-radius: 4px;
      &:before {
        display: none;
      }
      &__subtitle {
        display: none;
      }
    }

    .active {
      .seasonOptionBlock {
        background-color: theme.$primaryGrey;
        &__title {
          color: theme.$white;
        }
      }
    }

    // dont show icon on inline filters
    .seasonOptionBlock__icon {
      display: none;
    }
  }
}

.overviewCredits {
  display: flex;
  grid-gap: 20px;
  flex-direction: column;
}

.seasonGroup {
  position: relative;
  padding-right: 20px;
  cursor: pointer;

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
  }

  &__range {
    &::before {
      content: ' • ';
      color: theme.$tertiaryGrey;
      font-size: theme.$font-size-16;
    }
  }

  &__title {
    text-transform: uppercase;
  }

  &:hover {
    .seasonGroup__icon {
      display: block;
      fill: theme.$blue;
    }
    .seasonGroup__range {
      color: theme.$primaryGrey;
    }
    .seasonGroup__title {
      color: theme.$blue;
    }
  }
}

.productionsCount {
  margin-right: 16px;
  &::before {
    content: ' • ';
    color: theme.$tertiaryGrey;
    font-size: theme.$font-size-16;
  }
}

.productionListing {
  border: 1px solid theme.$whiteSmoke;
  border-radius: 8px;
  &__header {
    padding: 16px;
    border-bottom: 1px solid theme.$whiteSmoke;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_title_desktop {
      display: block;
    }
    &_title_mobile {
      display: none;
    }
  }
  &__filters,
  &__applied {
    padding: 12px 16px;
    border-bottom: 1px solid theme.$whiteSmoke;
  }
  &__list {
    padding: 16px;
  }

  &__criteria {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    border: 0;

    &__list {
      padding: 16px 0;
    }

    &__criteria {
      grid-gap: 16px;
      .productionListing__header {
        order: 3;
        border-bottom: 0;
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
        position: relative;
        p {
          margin-top: 24px;
        }
        &_title_desktop {
          display: none;
        }
        &_title_mobile {
          display: block;
        }
      }
      .productionListing__applied {
        order: 2;
        border-bottom: 0;
        padding: 0;
      }
      .productionListing__filters {
        order: 1;
        border-bottom: 0;
        padding: 0;
      }
    }
  }
}

.overviewProductions {
  margin: 20px 0;
}
