@import 'styles/theme/theme';

.breadCrumbs {
  width: 100%;
  @include container($tabPadding: 0, $mobilePadding: 0);
  padding: 12px 0 0px;
  margin: 0 auto;
  min-width: 0;
  &.condensed {
    padding: 0;
    .breadCrumbs__nav_items {
      margin: 0;
    }
  }

  &__nav {
    width: 100%;
    display: inline-flex;

    &_items {
      padding-left: 0px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;

     

      &_item {
        list-style-type: none;
        display: inline;
        

        &+&::before {
          content: " › ";
          margin: 5px;
          color: $secondaryGrey;
        }
      }
    }
  }

  .listItem {
    text-decoration: none;
    font-size: large;
    display: inline;
    color: $secondaryGrey;
    &_lastItem {
      display: inline;
    }
    &:hover {
      color: $blue;
      svg {
        fill: $blue;
      }
      span {
        color: $blue;
      }
    }
  }
}

.title {
  display: inline;
}

@media only screen and (max-width: 1280px) {
  .breadCrumbs {
    padding: 12px 0 12px;
    &__nav {
      &_items {
        &_item {
          display: inline;
          &_overlay {
            &+&::before {
              color: $white !important;
            }
          }
        }
        &_overlay {
          color: $white !important;
          filter: drop-shadow(0px 0px 4px $black);
          &:last-child {
            color: $white;
          }
        }
      }
    }
  }

  .overlayItem {
    color: $white;
    svg {
      fill: $white;
    }
    span {
      color: $white;
    }
    &:hover {
      svg {
        fill: $blue;
      }
      span {
        color: $blue;
      }
    }
  }
}